.add-video-channel {
    padding: 20px;
    width: 100%;

    .video-field {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 30px;
        width: 200px;
    }

    .form-field {
        margin-bottom: 20px;
    }

    .field-label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
        color: $text-color;
    }

    .required {
        color: $danger-color;
        margin-left: 2px;
    }

    .icon-grid {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        gap: 15px;
        align-items: center;
    }

    .icon-option {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;
        background-color: $background-color;
        box-shadow: 0 2px 4px $shadow-color;
    }

    .icon-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}
