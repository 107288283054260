.ad-field-setting {
    .field-mapping-container {
      display: flex;
      width: 300px;
      gap: 20px;
    }
  
    .database-fields,
    .ad-fields {
      flex: 1;
    }
  
    .database-fields {
      margin-right: 15px;
    }
  
    .ad-fields {
      margin-left: 15px;
    }
  
    .section-title {
      color: $shade-color;
      font-size: 14px;
      margin-bottom: 15px;
    }
  
    .field-item,
    .field-item-with-controls {
      margin-bottom: 10px;
      width: 300px;
    }
  
    .field-item-with-controls {
      display: flex;
      align-items: center;
    }
  
    .field-select {
      flex: 1;
    }
  
    .action-buttons {
      display: flex;
    }
  
    .icon-button {
      background: none;
      border: none;
      cursor: pointer;
      margin-left: 5px;
      padding: 4px 8px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s;
  
      &:hover {
        background-color: rgba($dark-black, $shadow-opacity);
      }
  
      &:focus {
        outline: 2px solid $primary-color;
        outline-offset: 2px;
      }
    }
  
    .add-button {
      color: $success-color;
    }
  
    .remove-button {
      color: $danger-color;
      font-size: 20px;
    }
  
    .icon-text {
      font-size: 20px;
      font-weight: bold;
    }
  
    .button-container {
      margin-top: 20px;
    }
  }
  