.reset-password {
  width: 42vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
  color: $read-only-disabled;
  font-size: 14px;

  p {
    margin: 0 !important;
  }

  .pwd-description {
    line-height: 2.5;

    .strong {
      font-weight: 600;
    }

    .pwd-valid {
      line-height: 1.8;
    }
  }

  .input-box {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .input-wrap {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      align-items: center;
    }
  }
}