.device-map {
  // display: grid;
  padding: 10px 10px 10px 0;

  .grid-container {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: auto 1fr auto;
    gap: 15px;

    .header {
      grid-area: 1 / 1 / 2 / 3;
    }

    .left {
      grid-area: 2 / 1 / 4 / 2;
      height: 100%;

      .card {
        height: 100%;

        .card-body {
          padding: 10px;
        }
      }

      .area-list-wrap {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .selected-area {
        padding: 0 0 0 10px;
        font-size: 14px;
      }
    }

    .right-top {
      grid-area: 2 / 2 / 3 / 3;

      .map-card {
        background-color: $white-color;
        border-radius: 8px;
        box-shadow: 2px 4px 10px $shadow-color;
        width: 100%;
        overflow: hidden;
        max-width: 100%;

        .card-header {
          padding: 15px;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .title {
            font-weight: 500;
            color: #292D31;
          }

          .header-right {
            .button-list {
              display: flex;
              flex-direction: row;
              gap: 15px;

              .button-item {
                display: flex;
                align-items: center;
                gap: 6px;
                cursor: pointer;
                color: #3E4246;
                font-size: 14px;
              }
            }
          }
        }

        .map-card-body {
          padding: 10px 15px 10px 15px;
          position: relative;
          height: 370px;
          overflow: auto;

          .image-container {
            display: inline-block;
            position: absolute;
            top: 0;
            width: 990px;
            max-width: 1200px;
            height: 360px;
            max-height: 100%;
            overflow: auto;
          }
        }
      }
    }

    .right-bottom {
      grid-area: 3 / 2 / 4 / 3;

      .real-time-events {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 10px 10px 5px;

        .table-wrapper {
          overflow: auto;
          height: 100%;
          width: 100%;

          .event-table {
            width: 1550px;
            max-height: 200px;
          }
        }

        .status-container {
          display: flex;
          justify-content: end;

          .clear-all {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-right: 15px;
            cursor: pointer;

            .text {
              // font-size: 14px;
              font-weight: 500;
              text-decoration: underline;
              color: rgb(105, 108, 111);

              &:hover {
                color: $primary-color;
              }
            }
          }

          .status-wrap {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-right: 15px;

            &:last-child {
              margin-right: 0px;
            }

            .circle-dot {
              width: 10px;
              height: 10px;
              border-radius: 50%;

              &.success {
                background-color: $success-text-color;
              }

              &.danger {
                background-color: $danger-color-2;
              }

              &.warning {
                background-color: $warning-badge-text;
              }

              &.secondary {
                background-color: $border-color;
              }
            }
          }
        }
      }
    }
  }
}