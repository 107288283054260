.note{
    .alter-icon{
        display: flex;
        width: 30px;
        padding-right: 15px;
    }
    .content{
        width: 50%;
    }
}
.valid-item{
    width: 20%;
}
.custom-color {
    color: $input-label-color;
  }
