.security-parameters {
    &-content {
        padding: 0 10px 10px 10px;       
         .section {
            margin-bottom: 20px;
            h4 {
                margin: 0 0 10px 0;
                font-weight: normal;
            }
        }
        .login-verification-section {
            .verification-options {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
            .select-box-container {
                width: 300px;
            }
        }
        .password-strength-section {
            .radio-options {
                display: flex;
                gap: 15px;
                margin-bottom: 10px;
            }
        }
        .login-settings-grid {
            .login-format {
                display: flex;
                flex-direction: row;
                gap: 20px;
                .grid-cell {
                    min-width: 200px;
                }
                .password-motification {
                    width: 360px !important;
                }
            }
        }
        .checkbox-section {
            margin-bottom: 20px;
        }
        .text-danger {
            margin-top: 0.5rem;
            .note {
                display: flex;
                align-items: flex-start;
                .alter-icon {
                    margin-right: 8px;
                }
            }
        }
        .defaultPasswordStrength{
            width: 360px;
            padding-bottom: 30px;
            h4 {
                margin-bottom: 10px;
                font-weight: normal;
            }
        }
        .password-msg{
            display: flex;
            padding-bottom: 21px;
        }
    }
}
