.privacy-management{
.system-privacy {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 20%;
    .button-field {
        display: flex;
        padding: 20px 0 20px 0 ;
    }
}
 
  .modal-content ul {
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .modal-content li {
    list-style-type: disc;
    margin-left: 20px;
    word-wrap: break-word;
  }
  .privacy-message{
    display: flex;
    flex-direction: row;
    padding-top: 20px;
  }
}