.enable-management{
    width: 600px;
    .note{
        display: flex;
        padding-bottom: 15px;
    }
}

.modal-header {
    border-radius: 8px;
}
.modal-body{
    p{
        padding-bottom: 10px;
    }
}
.cloud-setting {
.cloud-setting-form {
    max-width: 100%;

.column {
    display: flex;
    flex-direction: column;
    gap: 20px 10px;
    padding-left: 10px;

    .title{
     display: flex;
    padding-bottom: 25px;
    }
    .note{
        display: flex;
        padding-top: 20px;
        padding-bottom: 15px;
    }
    .link{ color: $primary-color; text-decoration: underline; cursor: pointer; }
    
    
}
}
.button-field{
        display: flex;
        padding: 20px;
 .btn {
    border: none;
}
}
}
