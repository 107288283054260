.synchronizePermission-setting{
.sync-settings{
.checkbox-group{
    display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 20px;
       

}
.sub-title{
    margin-bottom: 15px;

}
.domainNode{
    width: 300px;
    margin-bottom: 20px;
}
.warning-message{
    margin-bottom: 20px;
 
}
}
}