.text-success {
    color: $success-color;
}

.text-success-2 {
    color: $success-text-color;
}

.text-danger {
    color: $danger-color;
}

.text-primary {
    color: $primary-color;
}

.default-text {
    font-size: 14px;
    color: $input-label-color;
}

.text-warning {
    color: $danger-color-2;
}

.text-processing {
    color: $orange;
}

.form-buttons-right {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: end;

    &.add-margin {
        margin-top: 20px;
    }
}

.flex-row {
    display: flex;
    gap: 20px;

    &.column {
        flex-direction: column;
    }
    &.center {
        align-items: center;
    }
    &.space-between {
        justify-content: space-between;
    }
}

.list-hyperlink {
    text-decoration: underline;
    cursor: pointer;
    @include transition;
    @include hover-effect($primary-color);
}

.zk-msg-error {
    color: $danger-color;
}