.current-information {
  display: flex;
  flex-direction: column;
  gap: 25px;

  // Styles for tables below the chart
  .table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .table-title {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .tbl-container .table thead tr td {
    padding: 15px 10px;
    color: #3E4246;
  }

  .tbl-container .table tbody tr td {
    color: #3E4246;
    padding: 8px 0 8px 5px;
  }

  .chart-container {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    // justify-content: space-between;
    gap: 10px;

    .chart-wrapper {
      .chart-title {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
      }

      display: flex;
      flex-direction: column;
      gap: 10px;

      .charts {
        height: 100%;
        display: grid;
        place-content: center;
        justify-content: center;
      }
    }

    .chart-value {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .info-table-container {
    .table-wrapper {

      .tbl-container .table {
        box-shadow: none;
      }
    }
  }
}

// caching section

.caching {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .table-form {
    display: flex;
    gap: 10px;

    .tbl-container .table {
      box-shadow: none;
    }
  }
}

// trend-information 
.trend-information {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .chart-box {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      font-size: 14px;
      font-weight: 400;
    }

    .usage-box-wrapper {
      display: flex;
      gap: 30px;

      .usage-box {
        border: 1px solid #F6F6F7;
        padding: 10px 15px;
        width: max-content;
        display: flex;
        gap: 20px;
        align-items: center;
        border-radius: 8px;
      }

      .percentage {
        font-size: 24px;
        font-weight: 500;
        color: $primary-color;
      }
    }
  }
}