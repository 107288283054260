.permission-denied {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .img {
    width: 444px;
    height: 340px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .permission-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;

    .bottom-wrap {
      h2 {
        font-size: 32px;
        margin-bottom: 16px;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        color: $border-color;
      }
    }
  }

}