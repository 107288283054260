.delete-box {
    .message-text {
        padding: 40px;
        p {

        }
    }
    .mutli-text{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 10px;
        .selected-items-info {
            .selected-count {
                color: $danger-color;
                font-weight: bold;
            }
        }
    }
    .confirm-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 30px;
        padding: 10px 0px 20px;
    }
}