.button-field {
  display: flex;

  .btn {
    all: unset;
    padding: 10px 15px;
    text-align: center;
    border-radius: 3px;
    // border: solid 1px $border-color;
    border: 1px solid #CED0D3;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 10px;
    transition: all .3s ease;

    &.btn-primary {
      background-color: $primary-color;
      color: $white-color;
      border: none;
    }

    &.btn-outline-primary {
      color: $primary-color;
      border-color: $primary-color;

      &:hover {
        background-color: $primary-color;
        color: $white-color;
      }
    }

    &.row-reverse {
      flex-direction: row-reverse;
    }

    &.full-size {
      flex: 1;
      justify-content: center;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: $border-color;
    }

    &.btn-sm {
      font-size: 13px;
      padding: 7px 8px;
      font-weight: 500;
    }

    &.btn-bold {
      font-weight: 600;
      letter-spacing: 1.3px;
    }

    &.loading {
      pointer-events: none;
      opacity: 0.7;

      .spinner-loader {
        .spinner {
          width: 20px;
          height: 20px;
          display: grid;
          border-radius: 50%;
          -webkit-mask: radial-gradient(farthest-side, #0000 40%, #4797ff 41%);
          background: linear-gradient(0deg, rgba(71, 151, 255, 0.5) 50%, white 0) center/1.9px 100%,
            linear-gradient(90deg, $white-color 50%, white 0) center/100% 1.9px;
          background-repeat: no-repeat;
          animation: spinner-d3o0rx 1.4s infinite steps(12);
        }
      }
    }

    svg {
      path {
        stroke: currentColor;

        &:hover {
          stroke: $white-color;
        }
      }
    }
    &.d-none {
      display: none;
    }
  }
}