.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f7fc;
    height: 100vh;
    font-family: 'Arial, sans-serif';
    position: relative;

    .login-header {
        display: flex;
        justify-content: space-between;
        width: 82%;
        padding: 20px 40px;

        .logo {
            font-size: 32px;
            font-weight: 500;

            .logo-aic {
                color: black;
            }

            .logo-tec {
                color: blue;
            }
        }

        .subtitle {
            font-size: 12px;
            color: #000000;
            text-align: center;
            font-weight: 400;
        }

        .links {
            display: flex;
            flex-direction: row;
            gap: 10px;

            a {
                color: #007bff;
                margin-left: 20px;
                font-size: 14px;
            }
        }
    }

    .login-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        width: 100%;

        .login-image {
            img {
                width: 400px;
                height: auto;
            }
        }

        .login-form {
            padding: 40px 20px;
            margin-left: 340px;
            border-radius: 8px;
            width: 400px;

            &.ar {
                margin-left: 0;
                /* Reset margin-left for Arabic */
                margin-right: 340px;
            }

            h2 {
                font-size: 16px;
                color: #333333;
                font-weight: 400;
                margin-bottom: 20px;
            }

            .login-links {
                display: flex;
                gap: 32px;
                margin-bottom: 30px;

                .login-link {
                    cursor: pointer;
                    color: black;
                    text-decoration: none;
                    font-size: 28px;
                    font-weight: 500;
                    border: none;
                    background-color: #f4f7fc;

                    &.active {
                        color: #007bff;
                        text-decoration: underline;
                        font-size: 24px;
                    }
                }
            }

            form {
                display: flex;
                flex-direction: column;

                .username-input,
                .password-input,
                .verify-wrap {
                    margin-bottom: 30px;
                    background-color: #f4f7fc;
                }

                .verify-wrap {
                    display: flex;
                    gap: 20px;

                    .verify-input {
                        background-color: #f4f7fc;
                    }
                }

                .forgot-password {
                    align-self: flex-end;
                    color: #007bff;
                    text-decoration: none;
                    font-size: 14px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .checkbox-group {
                    display: flex;
                    align-items: center;
                    margin: 20px 0;

                    input[type='checkbox'] {
                        margin-right: 10px;
                    }

                    label {
                        font-size: 14px;
                        color: #333333;

                        a {
                            color: #007bff;
                            text-decoration: underline;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .or-divider {
                    margin: 20px 0;
                    text-align: center;
                    color: #333333;
                }

                .fingerprint-login {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 42px;
                        height: 43px;
                    }
                }

                .error-message {
                    color: $danger-color;
                }

                .checkbox-form-item {
                    margin: 20px 0px;
                }
            }
        }

        .captcha-message {
            color: $danger-color;
            padding-bottom: 20px;
        }

        .captcha-container {
            display: flex;
            flex-direction: row;
            gap: 20px;

            .captcha-image {
                height: 50%;
            }
        }
    }
}


@media (max-width: 1150px) and (min-width: 740px) {
    .login-container {
        .login-header {

            .logo {}

            .links {
                a {}
            }
        }

        .login-body {

            .login-image {
                img {}
            }

            .login-form {
                margin-left: 40px;

                .login-links {


                    .login-link {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 740px) and (min-width: 120px) {
    .login-container {
        .login-header {

            .logo {
                font-size: 28px;
            }

            .links {
                a {
                    font-size: 12px;
                }
            }
        }

        .login-body {

            .login-image {
                img {
                    display: none;
                }
            }

            .login-form {
                margin-left: 5px;

                .login-links {


                    .login-link {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}