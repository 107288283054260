.add-authorize {
  width: 46vw;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .grid-rows {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
  }
}

// export-key-file component styles
.export-key-file {
  display: flex;
  flex-direction: column;
  gap: 25px;

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}