$white-color: #ffffff;
$black-color: #212121;


$background-color: #F6F6F7;
$light-color: hsla(218, 13%, 88%, 0.921);

$background: #EEF5FD;
$shadow-color: #c8c2c2;
$shadow-color-2: #0000001A;
$border-color: #87909A;
$border-color-2: #696c6f6e;
$input-label-color: #696C6F;
$text-color: #3E4246;
$light-text-color: #D1D4D8;
$solid-color: #ccc;
$box-border-radius: 8px;

$primary-color: #078DEE;


$danger-color: #ff1313;
$danger-light-color: #fadbdb;
$danger-color-2: #E8212E;
$danger-light-bg: #E8212E1A;
$danger-brown-bg:  #FF3F4B4D;


$success-color: #2de009;
$success-light-color: #c5ffb9;
$success-text-color: #00A76F;
$success-green:#3FF6684D;

$warning-color: #eeea0f;
$warning-light-color: #f0efb2;
$text-disabled: #CED0D3;
$warning-badge-bg: #FF950033;
$warning-light-bg:#FFCC004D;
$warning-badge-text: #FF9500;

$shadow-opacity: 0.1;
$shadow-color: rgba(0, 0, 0, $shadow-opacity);
$input-disabled: #E3E6E8;
$read-only-disabled: #292D31;
$dark-black: #000000;
$read-only: rgba(220, 224, 224, 0.997);
$orange: #FF9500;
$backgrounds-color:#E0E0E0;
$shade-color: #333;
$red-shade:#F52E3A;
$green-shade:#11D13C;
$yellow-shade:#FFD324;
$ashade:#6c757d;
$box-shadow-1: #0000001C;
$white2: #D9D9D9;
$green2: #7ac142;
