.add-user-container {
    padding: 24px;
    .user-input-filelds{
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 222px;
        padding-bottom: 10px;
    }

    .login-permissions-checkboxes{
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px 0 20px 0px;
    }
    .resetPassword-fields{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 65%;
    padding-bottom: 20px; 
    }
    .maximum-number-field{
        width: 30%;
        padding-bottom: 10px;
    }

    .role-department-fields{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 65%;
    padding: 20px 0 20px 0;

    }

    .authorizeArea-fields{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 31%;
    padding: 20px 0 20px 0;
    }
    
    .personal-info-fields{
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px 0 20px 0;
    }
    .container-name{
        display: flex;
        justify-content: flex-start;
    }
  }
  