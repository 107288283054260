.message-tip {
  position: absolute;
  bottom: 6%;
  right: 2%;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: all 1s ease-in-out;

  .card-status {
    background-color: $white-color;
    width: 230px;
    height: 300px;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 0px 14.1px 2px $box-shadow-1;
    cursor: default;

    .card-body {
      text-align: center;

      .card-image {
        width: 100px;
        height: 100px;
        margin: 0 auto;
        margin-top: 10px;
      }

      .content-body {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .text {
          font-size: 14px;
          color: #292D31;
          font-weight: 400;

          &.status {
            font-weight: 500;
          }
        }
      }
    }
  }
}