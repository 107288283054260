.server-configuration {
  .server-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    
    .form-group {
      width: 260px;
      
      .server-filed {
        margin-bottom: 10px;
      }
    }
  }

  .Domain-filed {
    display: flex;
    padding: 20px 0 10px 0;
  }

  .domain-node-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .domain-node-field {
    position: relative;
    width: 276px;
    display: flex;

    .add-button {
      border: none;
      background-color: $white-color;
      color: $success-text-color;
    }

    .delete-button {
      border: none;
      background-color: $white-color;
      color: $danger-color;
      font-size: 20px;
    }
  }

  .test-connection-button {
    margin-top: 15px;
  }
}
