.add-level-modal {
    width: 30vw;

    .forms-row {
        width: 260px;
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
}

.level-settings-container {
    width: 100%;
    height: 226px;
    border: 1px solid $backgrounds-color;
    border-radius: 8px;
    padding: 10px;
    background-color: $white-color;
    box-shadow: $dark-black;
    display: flex;
    flex-direction: column;

    .level-settings {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .centered-add-button {
            border: none;

            &:hover {}

            &::after {
                content: '+';
                margin-left: 10px;
            }
        }

        &.hide-add-button {
            display: none;
            height: 0; // Ensures height is removed when hidden
        }
    }

    .level-settings-header {
        display: flex;
        justify-content: space-between;
        // align-items: center;

        margin-bottom: 10px;

        .add-button {
            margin: auto 0;
        }
    }

    .level-settings-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 150px;
        overflow-y: auto;
        margin-top: 0;

        .checkbox-item {
            margin-bottom: 10px;
        }
    }

}

.add-header {
    display: flex;
    flex-direction: row;
    gap: 63%;
    padding: 40px 10px 10px 10px;

    .add-button {
        background-color: $backgrounds-color;
        border: none;
        border-radius: 4px;
        padding: 5px 10px;
        font-size: 12px;
        color: #333333;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: $backgrounds-color;
        }
    }
}
