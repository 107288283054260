.video-channel{
    .delete-icon {
        display: flex;
        flex-direction: row;
         gap: 20px;
    }
    .delete-button{
        border: none;
        color: $text-color;
        cursor: pointer;
        background-color: $white-color
    }
}