.door-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  .doorlist {
    padding: 15px;
  }

}


.modal-header {
  background: $background;
}

.modal-page {
  .statistics-page {
    .rows {
      .rows {
        display: flex;
        flex-direction: column;
        gap: 57px;
      }

      .filetype {
        width: 40%;
      }
    }
  }
}
.export-door-data {
  width: 40vw;
  .rows-4 {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
  }
}
.modal-page {
  .statistics-page {
      .rows {
          display: flex;
          flex-direction: column;
          gap: 57px;
      }
  }
}
.modal-page {
  .statistics-page {
      .rows {
          .filetype {
              width: 40%;
          }
      }
  }
}