.notification {
    display: flex;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;

    &.active {
        visibility: visible;
        transform: translateY(100px);
        opacity: 1;
    }

    &.bottom {
        top: auto;
        bottom: 0;
        transform: translateY(100%); // Initially off-screen at bottom

        &.active {
            transform: translateY(600px); // Move into view from bottom
        }
    }

    .notification-content {
        position: relative;
        background-color: #fefefe;
        min-width: 20%;
        max-width: 550px;
        // top: 100px;
        padding: 10px;
        // border: 1px solid #888;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

        display: flex;
        gap: 10px;
        align-items: center;

        &.danger {
            // border-color: #FEE4E5;
            border: 0;
            background-color: $danger-light-color;
            color: $danger-color;
            box-shadow: 0px 0px 5px 3px #F0443840;
            backdrop-filter: blur(4px);
        }

        &.success {
            border-color: $success-color;
            background: $success-light-color;
            color: $success-color;
        }

        &.warning {
            border-color: $warning-color;
            background: $warning-light-color;
            color: $warning-color;
        }

        .notification-message {
            flex: 1;
        }

        .notification-close {
            cursor: pointer;
        }
    }
}