.data-setting{
    .checkbox-group{
        margin-bottom: 20px;

    }
    .input-group{
        width:300px ;
    }
    .globalSync-group{
        display: flex;
        padding: 40px 0 20px 0;
    }
    .every-group{
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 160px;
        margin-bottom: 20px;
    }
}