.role-input-filelds {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 30%;
  padding: 10px 0 10px 0;
}

.personnel-Tab {

  .role-tree-section {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    height: 250px;
    overflow-y: scroll;
  }

  .select-all {
    background-color: #EEF5FD;
    padding: 5px 0 3px 15px;
    width: 100%;
  }
}

.role-container {
  padding: 15px;
  width: 40vw;
}

.role-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.input-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.permissions-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
}