        .delete-icon {
            display: flex;
            flex-direction: row;
             gap: 20px;
        .multi-delete-button{
            border: none;
            color: $text-color;
            cursor: pointer;
            background-color: $white-color;
            gap:4px;
            font-size: 14px;
        }
    .filter-section{
        display: grid;
        padding-top: 5px;
        margin-right: 20px;
    }
}
