.real-time-monitoring {
  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    .filters {
      display: grid;
      grid-template-columns: 35% 17% 17%;
      gap: 20px;
      padding: 0 0 15px 10px;
    }

    .tab-page {
      padding-top: 0;
    }

    .real-time-events {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 10px 10px 5px;

      .table-wrapper {
        overflow-x: auto;
        width: 100%;

        .event-table {
          width: 1550px;
        }
      }

      .status-container {
        display: flex;
        justify-content: end;

        .clear-all {
          display: flex;
          align-items: center;
          gap: 5px;
          margin-right: 15px;
          cursor: pointer;

          .text {
            // font-size: 14px;
            font-weight: 500;
            text-decoration: underline;
            color: rgb(105, 108, 111);

            &:hover {
              color: $primary-color;
            }
          }
        }

        .status-wrap {
          display: flex;
          align-items: center;
          gap: 5px;
          margin-right: 15px;

          &:last-child {
            margin-right: 0px;
          }

          .circle-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;

            &.success {
              background-color: $success-text-color;
            }

            &.danger {
              background-color: $danger-color-2;
            }

            &.warning {
              background-color: $warning-badge-text;
            }

            &.secondary {
              background-color: $border-color;
            }
          }
        }
      }
    }
  }
}